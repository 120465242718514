var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Clear user application settings")]),
          _c("v-card-text", [
            _c("p", [
              _vm._v(" This will stop "),
              _c("b", [_vm._v("all applications")]),
              _vm._v(
                " and clear global user application settings. This can help to reset certain applications (e.g. Rstudio) to a clean state. Please note that this operation can affect "
              ),
              _c("b", [_vm._v("all of your applications")]),
              _vm._v(" . "),
            ]),
            _c("p", [
              _vm._v(
                "A backup snapshot will be created to be able to revert this operation if needed."
              ),
            ]),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.reset } },
                [_vm._v("Clear settings")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }